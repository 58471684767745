import React, { useEffect, useState } from 'react';

export default function UISlider({ children }) {
    const [activeChild, setActiveChild] = useState(0);

    function handlePrev() {
        if (activeChild > 0) {
            setActiveChild((activeChild) => activeChild - 1);
        } else {
            setActiveChild(children.length - 1);
        }
    }

    function handleNext() {
        if (activeChild < children.length - 1) {
            setActiveChild((activeChild) => activeChild + 1);
        } else {
            setActiveChild(0);
        }
    }

    useEffect(() => {
        if (children.length === 1) {
            setActiveChild(0);
        }
    }, [children]);

    return (
        <div className="relative">
            {children.length > 1 && (
                <React.Fragment>
                    <div
                        onClick={() => handlePrev()}
                        className="absolute z-50 flex items-center justify-center w-12 h-12 transform -translate-y-12 cursor-pointer opacity-80 hover:opacity-100 top-1/2">
                        <div className="flex w-4 h-4 -rotate-45 border-t-2 border-l-2 border-white"></div>
                    </div>
                    <div
                        onClick={() => handleNext()}
                        className="absolute right-0 z-30 flex items-center justify-center w-12 h-12 transform -translate-y-12 cursor-pointer opacity-80 hover:opacity-100 top-1/2">
                        <div className="flex w-4 h-4 -rotate-45 border-b-2 border-r-2 border-white"></div>
                    </div>
                </React.Fragment>
            )}
            {children[activeChild]}
        </div>
    );
}
