import { useEffect } from "react";
import { Fragment, useState } from "react";
import { getBanners } from "../../utils/getProducts";

export default function UIModalItem({ data, catalog, closeModal }) {

    const [complementaryInfo, setComplementaryInfo] = useState(null);
    const rootElement = document.getElementById('root');

    // checkbrand data
    useEffect(() => { rootElement.classList.add('locked'); });
    useEffect(() => {
        getBanners(catalog).then((res) => {
            console.log('DEBUG', res);
            let bannersMarca = res.filter((item) => item.Marca === data.MARCA);
            let lookup = bannersMarca.find((item) => item.VARIEDAD === data.VARIEDAD)
            if (lookup) { setComplementaryInfo(lookup); }
            else {
                const value = res.filter((item) => item.Marca === data.MARCA)[0];
                setComplementaryInfo(value);
            }
        });
    }, [catalog, data]);

    function replaceSpacesWithHyphens(str) {
        return str.replace(/ /g, '-');
    }
    function handleCloseModal() { closeModal(); rootElement.classList.remove('locked'); }

    return (
        <Fragment>
            <div className="fixed z-[9999] top-0 bottom-0 left-0 right-0 bg-black bg-opacity-60 backdrop-blur-sm" />
            <div className="fixed z-[9999] top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center">
                <div className="absolute animate-fade-in-down overflow-hidden rounded-xl flex bg-white mx-auto w-[90%] lg:w-auto lg:max-w-[800px] h-[90%] lg:h-[70%] lg:max-h-[500px]">
                    <div className="flex flex-col w-full h-full overflow-y-auto bg-white custom-scroll lg:overflow-hidden lg:flex-row">

                        {/* boton close */}
                        <div onClick={() => handleCloseModal()} className='absolute cursor-pointer z-[9999] top-5 right-5'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>

                        {/* imagen */}
                        <div className="flex w-full lg:w-full items-center justify-center bg-[#E6E5E5]">
                            {data.IMAGEN && <img src={data.IMAGEN} alt={data.DESCRIPCION} className="flex mx-auto transition-all bg-center bg-no-repeat aspect-square " />}
                        </div>

                        {/* content */}
                        <div className="relative flex flex-col w-full p-8 lg:overflow-y-auto custom-scroll">

                            {/* DESCRIPCION y GRADOS */}
                            {data.DESCRIPCION && <div className="relative flex items-center text-lg italic font-bold ">
                                {data.DESCRIPCION} </div>
                            }
                            {data.GRADOS !== null &&
                                <div className="relative flex items-center py-2 italic border-b border-yellow-500">
                                    {data.GRADOS} °Alc </div>
                            }
                            <div className="relative flex items-center py-2 italic border-b border-yellow-500">
                                {data.TAMAÑO === 'LITRO' ? <div className="card-product--size__text"> {' '} LITRO{' '} </div> : <div className="font-bold  text-[15px] text-gray"> {data.TAMAÑO} <span className="text-xs"> cc. </span> </div>}
                            </div>

                            {/* PALLETS y EAN */}
                            <div className="flex justify-between py-2 text-xs font-medium text-gray">
                                {data['CAJAS POR PALLET'] && (<div className=""> {data['CAJAS POR PALLET']} cajas por pallet </div>)}
                                <div className="uppercase ">
                                    {catalog === 'koe' && `COD KOE : ${data['COD KOE']}`}
                                    {catalog === 'koa' && `COD KOA : ${data['COD KOA']}`}
                                    {catalog === 'spmk' && (
                                        <div className="flex flex-col items-end justify-end">
                                            <div> {data['EAN (INTERMEDIO)']} </div>
                                            <div> COD KOA :{' '} {data['COD KOA']} </div>
                                            <div> COD KOE :{' '} {data['COD KOE']} </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* INFO COMPLEMENTARIA */}
                            {complementaryInfo &&
                                <div className="px-4 pt-0 pb-3 mt-4 mb-4 text-xs border border-neutral-400 rounded-xl">
                                    {complementaryInfo.Atributos && <div className="flex pt-3 pb-0 text-base font-bold">{complementaryInfo.Atributos}</div>}
                                    {complementaryInfo['Argumento de Venta'] &&
                                        <Fragment>
                                            {console.log(complementaryInfo)}
                                            <div className="flex w-full my-4 border-b" />
                                            <div className="flex flex-col mt-2"><span className="font-semibold">Argumento venta:</span>{complementaryInfo['Argumento de Venta']}</div>
                                        </Fragment>
                                    }
                                    {complementaryInfo.Maridaje &&
                                        <div className="flex flex-col mt-2">
                                            <div className="flex w-full my-4 border-b" />
                                            <span className="font-semibold">Maridaje:</span>
                                            <div>
                                                {complementaryInfo.Maridaje.split(',').map((item, index) => {
                                                    return (
                                                        <div key={index} className="flex flex-wrap items-center justify-between" >
                                                            <span className="text-xs text-gray-500">{item}</span>
                                                            <img className="w-14 h-14 brightness-0" src={`/assets/icons/${replaceSpacesWithHyphens(item)}.svg`} alt='icon' />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                </div>
                            }

                            {complementaryInfo &&
                                <div>
                                    {/* {complementaryInfo.ABV &&
                                        <div className="relative flex items-center justify-between py-2 italic border-b border-yellow-500">
                                            <span className="font-semibold">ABV:</span>{complementaryInfo.ABV}
                                        </div>
                                    } */}
                                    {complementaryInfo.Color &&
                                        <div className="relative flex items-center justify-between italic border-b border-yellow-500">
                                            <span className="font-semibold">Color:</span>{complementaryInfo.Color}
                                        </div>
                                    }
                                    {complementaryInfo.Estilo &&
                                        <div className="relative flex items-center justify-between py-2 italic border-b border-yellow-500">
                                            <span className="font-semibold">Estilo:</span>{complementaryInfo.Estilo}
                                        </div>
                                    }
                                    {complementaryInfo.IBU &&
                                        <div className="relative flex items-center justify-between py-2 italic border-b border-yellow-500">
                                            <span className="font-semibold">IBU:</span>{complementaryInfo.IBU}
                                        </div>
                                    }
                                    {complementaryInfo.Origen &&
                                        <div className="relative flex items-center justify-between py-2 italic border-b border-yellow-500">
                                            <span className="font-semibold">Origen:</span>{complementaryInfo.Origen}
                                        </div>
                                    }
                                    {complementaryInfo['Principales Competidores'] &&
                                        <div className="relative flex flex-col py-2 italic">
                                            <div className="font-semibold">Competidores:</div>
                                            <div className="text-xs">{complementaryInfo['Principales Competidores']}</div>
                                        </div>
                                    }
                                </div>
                            }




                            {/* BARCODE */}
                            {catalog === 'spmk' ? (
                                <div className="p-4 py-8 mx-auto">
                                    {data['EAN (INTERMEDIO)'] && (<img src={`https://barcode.tec-it.com/barcode.ashx?data=${data['EAN (INTERMEDIO)'].toString()}&code=Code128&translate-esc=on`} alt="img producto" />)}
                                </div>
                            ) : null}
                        </div>

                    </div>
                </div>
            </div>
        </Fragment >
    )
}