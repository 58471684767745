export const slider = [
    {
        name: 'clásica',
        sku: '/assets/BannerSlider/sku-format-classic-full.png',
        bg: '#203264',
        logo: '/assets/BannerSlider/logo-format-1.svg',
        text: 'Para sed de la buena ¡La refrescante de siempre! Becker Classic Lager está hecha con ingredientes 100% naturales, maltas y cereales seleccionados que le dan la refrescancia clásica de Becker.',
        maridaje: {
            data: 'COLOR: Dorado\nESTILO: Lager\nABV: 4.5\nIBU: 14.5',
            icons: [
                { image: '/assets/BannerSlider/icon-format-clasica.svg', label: 'Empanadas' },
                { image: '/assets/BannerSlider/icon-format-clasica-2.svg', label: 'Sandwiches' },
            ],
        },
    },
     {
        name: 'golden',
        sku: '/assets/BannerSlider/sku-format-golden-full.png',
        bg: '#E0B13D',
        logo: '/assets/BannerSlider/logo-format.svg',
        text: 'Una verdadera joya, para que cada momento valga oro. Becker Golden está hecha con ingredientes 100% naturales y dos tipos de malta seleccionadas que le dan el equilibrio perfecto entre refrescancia, cuerpo y sabor.',
        maridaje: {
            data: 'COLOR: Dorado\nESTILO: Lager\nABV: 5\nIBU: 16.5',
            icons: [
                { image: '/assets/BannerSlider/icon-format.svg', label: 'Pescados Blancos grillados' },
                { image: '/assets/BannerSlider/icon-format-dorada-2.svg', label: 'Verduras salteadas' },
            ],
        },
    },
    {
        name: 'roja',
        sku: '/assets/BannerSlider/sku-format-roja-full.png',
        bg: '#CA2539',
        logo: '/assets/BannerSlider/logo-format-roja.svg',
        text: 'Nos pusimos rojos y no de vergüenza, ¡de puro sabor a caramelo!\n\n Becker Red Lager está hecha con ingredientes 100% naturales y una combinación de maltas que la hacen una cerveza roja estilo Amber Lager con un sabor dulce y más cuerpo.',
        maridaje: {
            data: 'COLOR: Dorado\nESTILO: Lager\nABV: 5.1\nIBU: 13',
            icons: [
                { image: '/assets/BannerSlider/icon-format-roja.svg', label: 'Pastas con salsa de tomate' },
                { image: '/assets/BannerSlider/icon-format-roja-2.svg', label: 'Cerdo agridulce' },
            ],
        },
    },
    {
        name: 'negra',
        sku: '/assets/BannerSlider/sku-format-negra-full.png',
        bg: '#222222',
        logo: '/assets/BannerSlider/logo-format-negra.svg',
        text: 'Sí, tenemos un tostado fascinante todo el año.\nBecker Black Lager está hecha con una combinación de maltas tostadas e ingredientes 100% naturales, que la hacen una cerveza negra estilo Stout con un especial sabor amargo.',
        maridaje: {
            data: 'COLOR: Dorado\nESTILO: Lager\nABV: 5.6\nIBU: 18',
            icons: [
                { image: '/assets/BannerSlider/icon-format-negra.svg', label: 'Carne al jugo' },
                { image: '/assets/BannerSlider/icon-format-negra-2.svg', label: 'Postres Cremosos' },
            ],
        },
    },
];