export default function UISkeletonCell() {
    return (
        <div className="flex flex-col w-full gap-4 p-0 rounded-lg animate-pulse">
            <div className="flex flex-grow w-full mb-8 rounded-lg bg-slate-50 shrink-0 aspect-square"></div>
            <div className="flex w-full gap-10 mb-6 rounded-full">
                <div className="flex w-full h-4 rounded-full bg-slate-100"></div>
                <div className="flex w-20 h-4 rounded-full bg-slate-100"></div>
            </div>
            <div className="flex w-full h-1 rounded-full bg-slate-100"></div>
            <div className="flex w-full gap-10 pt-4 pb-1 rounded-full">
                <div className="flex w-20 h-4 rounded-full bg-slate-100"></div>
            </div>
            <div className="flex w-full h-1 rounded-full bg-slate-100"></div>
            <div className="flex w-full rounded-full">
                <div className="flex w-full h-2 rounded-full bg-slate-100"></div>
                <div className="flex w-full h-2 rounded-full "></div>
                <div className="flex w-20 h-2 rounded-full bg-slate-100"></div>
            </div>
        </div>
    );
}
