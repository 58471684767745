export const slider = [
    {
        name: 'Goose Ipa',
        sku: '/assets/goose/sku-format-goose-ipa.png',
        bg: '#41924D',
        textColor: '#FFFFFF',
        logo: '/assets/goose/log-format-goose-ipa.svg',
        text: 'Aroma cítrico con fuerte sabor a lúpulo. Seco pero suave.',
        maridaje: {
            data: 'Estilo : URBAN WHEAT\nABV : 5.9\nIBU : 55\nColor : Dorado',
            icons: [
                { image: '/assets/BannerSlider/icon-format.svg', label: 'Austral' },
                { image: '/assets/BannerSlider/icon-format-dorada-2.svg', label: 'Kunstmann' },
            ],
        },
    },
    {
        name: 'Goose 312',
        sku: '/assets/goose/sku-format-goose-312.png',
        bg: '#FFE326',
        textColor: '#000000',
        logo: '/assets/goose/log-format-goose-312.svg',
        text: 'Cuerpo suave y cremoso que logra una cerveza inmensamente refrescante.',
        maridaje: {
            data: 'Estilo : URBAN WHEAT\nABV : 4.2\nIBU : 18\nColor : Dorado',
            icons: [
                { image: '/assets/BannerSlider/icon-format-clasica.svg', label: 'Austral' },
                { image: '/assets/BannerSlider/icon-format-clasica-2.svg', label: 'Kunstmann' },
            ],
        },
    },

];