import { Fragment, useEffect, useState } from 'react';

export default function SliderCusItem({ item, destroy, setDestroy }) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (destroy) {
            setAnimate(false);
            setTimeout(() => {
                setDestroy(false);
            }, 500);
        } else {
            setAnimate(true);
        }
    }, [destroy, setDestroy]);

    return (
        <div
            className="flex flex-col w-full gap-0 overflow-hidden transition-all duration-75 print:gap-4 md:gap-5 md:flex-row print:flex-row"
            style={{
                opacity: animate ? 1 : 0,
            }}>
            {/* sku */}
            {animate && (
                <Fragment>
                    <div
                        style={{ backgroundColor: item.color }}
                        className="absolute mix-blend-multiply bottom-0 h-[230px] bg-center bg-cover md:h-[210px] print:h-[210px] right-0 md:flex z-[0] left-0" />
                    <div
                        className="md:h-[400px] print:h-[350px] h-[220px]  flex hover:scale-100 z-10 transform transition-all duration-1000 shrink-0 bg-no-repeat bg-contain bg-bottom md:w-1/2 print:w-1/2"
                        style={{ backgroundImage: `url(${item.sku})` }} />

                    {/* info */}
                    <div className="flex flex-col py-8 print:py-0 max-w-[510px] h-full md:w-1/2 gap-4">
                        <div className="flex flex-col h-full text-xs grow print:animate-none animate-delayed-fade-in-left md:text-sm">
                            <div
                                className="h-[60px] md:h-[60px] print:h-[60px]  transition-all print:animate-none animate-fade-in-right print:bg-bottom  w-full bg-no-repeat flex bg-contain bg-left"
                                style={{
                                    backgroundImage: `url(${item.logo})`,
                                }}
                            />
                            {item.text.split('\n').map((text, index) => (
                                <p
                                    className="mb-3 uppercase text-[12px] md:text-[15px] font-NorpethBold text-[#1E1E1E]"
                                    key={index}>
                                    {text}
                                </p>
                            ))}
                        </div>
                        {/* info box */}
                        <div className="flex flex-col gap-2 w-full h-[180px] print:animate-none animate-fade-in-right">
                            <div className="flex gap-1 justify-between items-center p-2 text-[14px] ] ">
                                {item.maridaje.data
                                    .split('\n')
                                    .map((text, index) => (
                                        <div
                                            className="flex flex-col items-center"
                                            key={index}>
                                            <div className={`px-1 font-bold py-0 text-xs tracking-widest text-white uppercase bg-[${item.maridaje.secondaryColor}] top-2`}
                                                style={{ backgroundColor: item.maridaje.secondaryColor, color: item.maridaje.labelColor }}>
                                                {text.split(':')[0]}
                                            </div>
                                            <div className="uppercase print:text-[20px] font-NorpethBold text-[32px] text-black font-black tracking-tight"
                                                style={{ color: item.maridaje.color }}>
                                                {text.split(':')[1]}
                                            </div>
                                        </div>
                                    ))}
                                <img
                                    className={`h-[70px] flex`}
                                    style={{
                                        color: item.maridaje.secondaryColor,
                                        filter: `brightness(${item.maridaje.secondaryColor === '#fff' | item.maridaje.labelColor === '#fff' ? 10 : 1})`,
                                    }}
                                    alt='sku'
                                    src="/assets/cus/badge.svg"
                                />
                            </div>
                            <div className={`relative flex flex-col flex-wrap w-full pt-5 p-4 pb-2 border-2 border-[${item.maridaje.secondaryColor}] rounded-2xl`}>
                                <div
                                    className={`absolute px-3 py-1 text-xs font-bold text-white uppercase -top-2 `}
                                    style={{ backgroundColor: item.maridaje.secondaryColor, color: item.maridaje.labelColor }}>
                                    maridaje:
                                </div>
                                {item.maridaje.icons.map((icon, index) => (
                                    <div

                                        className="flex font-RodfatTwo text-[12px] md:text-[13px] uppercase leading-tight  text-[#1E1E1E] items-center justify-center flex-col"
                                        style={{ color: item.maridaje.color }}
                                        key={index}>
                                        {icon.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
            }
        </div >
    );
}
