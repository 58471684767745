import { useContext } from 'react';
import { LegalContext } from '../context/LegalContext';
import UIButton from './UI/UIButton';

export default function Cookies() {
    const { setLegal } = useContext(LegalContext);

    function setCookiesInLocalStorage(e) {
        localStorage.setItem('cookies', e);
        setLegal(true);
    }

    return (
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-black z-[9999]">
            <div className="container relative flex flex-col gap-4 md:flex-row">
                <div className="flex flex-col text-white text-xxs">
                    This site uses cookies and other automated tools to
                    understand and improve our site, provide personalized
                    content and customized experiences. By
                    clicking “Accept Cookies” you agree to the storing of
                    cookies on your device, you also consent-
                    according to Art. 49 para. 1 p. 1 lit. a GDPR – to the
                    transfer of your data by Google, Facebook, Twitter, Youtube,
                    and other providers to recipients outside the European
                    Economic Area without an adequate level of protection under
                    data protection law (esp. USA).
                </div>
                <UIButton
                    onClick={() => setCookiesInLocalStorage(true)}
                    secondary>
                    {' '}
                    Accept All Cookies
                </UIButton>
                {/* <div>Close</div> */}
            </div>
        </div>
    );
}
