import axios from "axios";

export const postCRM = async (body = bodyPrototype) => {
    try {
        const { data } = await axios.post(
            `https://catalogo-crm.up.railway.app/api/interactions`, body, {

            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const bodyPrototype = {
    "data": {
        "email": "kvelasquezcastro@gmail.com",
        "start": "2023-02-19T21:51:00.000Z",
        "url": "https://www.catalogoabichile.cl/spmk/corona",
        "event": "pageload",
        "end": "2023-02-19T22:10:00.000Z",
        "geolocation": "-12.1375035,-76.9813247",
        "marca": "baltica",
        "categoria": "value",
        "createdAt": "2023-02-19T15:36:26.196Z",
        "updatedAt": "2023-02-19T15:36:26.196Z",
        "catalogo": "spmk"
    }
}