import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { NavLink, useParams } from 'react-router-dom';
import { LoginContext } from '../context/LoginContext';

export default function NavbarLinks() {
    const { auth } = useContext(LoginContext);
    // const [location, setLocation] = useState('');
    const [location] = useState('');
    const { catalog } = useParams();

    // function GetLocation() {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         setLocation({
    //             latitude: position.coords.latitude,
    //             longitude: position.coords.longitude,
    //         });
    //     });
    // }

    useEffect(() => {
        // GetLocation();
    }, []);

    return (
        <React.Fragment>
            <NavLink
                className={({ isActive }) =>
                    isActive
                        ? 'border-b-2 p-4 w-full md:w-auto justify-center flex hover:bg-gray-100 rounded-t border-primary'
                        : 'p-4 w-full md:w-auto justify-center flex rounded hover:bg-gray-100'
                }
                to={`/${catalog}/`}>
                Catálogo
            </NavLink>
            <div
                className="flex justify-center w-full p-4 rounded cursor-pointer md:w-auto hover:bg-gray-100"
                onClick={() => {
                    localStorage.removeItem('auth');
                    window.location.href = '/';
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'logout',
                            catalogo: catalog,
                            userId: auth.mail,
                            geolocation:
                                location.latitude + ',' + location.longitude,
                        },
                    });
                }}>
                Logout
            </div>
            <div className="hidden">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-8 h-8">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                </svg>
            </div>
        </React.Fragment>
    );
}
