import React, { useContext } from 'react';
import { LoginContext } from '../context/LoginContext';
import Footer from './Footer';
import Header from './Header';

function Layout({ children }) {
    const { auth } = useContext(LoginContext);

    return (
        <main className="relative flex flex-col flex-grow min-h-screen">
            {auth !== null && <Header />}
            {children}
            <Footer />
        </main>
    );
}

export default Layout;
