import React, { Fragment, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import UIBannerItem from '../components/UI/UIBannerItem';
import UICardItem from '../components/UI/UICardItem';
import UIFilters from '../components/UI/UIFilters';
import UIInputOutSelect from '../components/UI/UIInputOutSelect';
import UIPickerHeader from '../components/UI/UIPickerHeader';
import UISkeleton from '../components/UI/UISkeleton';
import { LoginContext } from '../context/LoginContext';
import { bannerWhatsapp, brandsByCatalog, categories } from '../utils/data';
import { getBanners, getProducts, getUsers } from '../utils/getProducts';
import TagManager from 'react-gtm-module';
import handlerCRM from '../utils/handlerCRM';
import SliderBecker from '../components/BannerSlider/SliderBecker';
import SliderCus from '../components/BannerCusqueña/SliderCus';
import BannerGoose from '../components/BannerGoose/BannerGoose';
import BannerKm from '../components/BannerKm/BannerKm';

export default function Catalog() {
    const navigate = useNavigate();
    const { auth } = useContext(LoginContext);
    let { catalog } = useParams();
    let { brand } = useParams();

    // const [location, setLocation] = React.useState('');
    const [location] = React.useState('');
    const [loaded, setLoaded] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [banner, setBanner] = React.useState('');
    const [allBanners, setAllBanners] = React.useState('');
    const [productsFull, setProductsFull] = React.useState('');
    const [products, setProducts] = React.useState('');
    const [printDetails, setPrintDetails] = React.useState(true);

    // brand and categories state and filters
    const [selectedCategory, setSelectedCategory] = React.useState('');
    const [selectedBrand, setSelectedBrand] = React.useState('');
    const [filteredBrands, setFilteredBrands] = React.useState(brandsByCatalog.find((brand) => brand.name === catalog).brands);

    function handleCategoryChange(e) {
        setSelectedCategory(e);
        if (e === 'Todas las categorías') {
            handlerCRM('click', auth.mail, window.location.pathname, location.latitude + ',' + location.longitude, brand, 'Todas las categorías', catalog);
            navigate(`/${catalog}`);
            setFilteredBrands(brandsByCatalog.find((brand) => brand.name === catalog).brands);
            TagManager.dataLayer({ dataLayer: { event: 'seleccionar_categoria', catalogo: catalog, categoria: e, userId: auth.mail, geolocation: location.latitude + ',' + location.longitude, }, });
        } else {
            handlerCRM('click', auth.mail, window.location.pathname, location.latitude + ',' + location.longitude, brand, e, catalog);
            setFilteredBrands(brandsByCatalog.find((brand) => brand.name === catalog).brands.filter((brand) => brand.category === categories.filter((category) => category.value === e)[0].id));
            TagManager.dataLayer({ dataLayer: { event: 'seleccionar_categoria', catalogo: catalog, categoria: e, userId: auth.mail, geolocation: location.latitude + ',' + location.longitude, }, });
        }
    }

    function handleBrandChange(e) {
        if (e === 'Todas las marcas') {
            handlerCRM('click', auth.mail, window.location.pathname, location.latitude + ',' + location.longitude, 'Todas las marcas', selectedCategory, catalog);
            setSelectedBrand('');
            navigate(`/${catalog}`);
            TagManager.dataLayer({ dataLayer: { event: 'seleccionar_marca', catalogo: catalog, marca: e, categoria: selectedCategory, userId: auth.mail, geolocation: location.latitude + ',' + location.longitude, }, });
        } else {
            handlerCRM('click', auth.mail, window.location.pathname, location.latitude + ',' + location.longitude, e, selectedCategory, catalog);
            setSelectedBrand(e);
            setSelectedCategory('Todas las categorías');
            navigate(`/${catalog}/${e}`); //enviarbrand Value
            TagManager.dataLayer({ dataLayer: { event: 'seleccionar_marca', catalogo: catalog, marca: e, categoria: selectedCategory, userId: auth.mail, geolocation: location.latitude + ',' + location.longitude, }, });
        }
    }

    // on PAGELOAD checkAuth local storage
    useEffect(() => {
        const mail = JSON.parse(localStorage.getItem('auth')).mail;
        getUsers().then((data) => {
            const user = data.filter((user) => user.mail === mail);
            if (user.length > 0) { setTimeout(() => { localStorage.setItem('auth', JSON.stringify(user[0])); }, 2000); }
            else { localStorage.removeItem('auth'); }
        });
    }, []);

    useEffect(() => {
        if (catalog === 'koe') { setPrintDetails(true); }
        else if (catalog === 'koa') { setPrintDetails(true); }
        else { setPrintDetails(false); }
    }, [catalog]);

    //UPDATE products from API and
    useEffect(() => {
        setLoaded(false);
        getProducts(catalog).then((data) => {
            if (data === 'error') { setError(true); }
            else {
                setError(false);
                if (brand !== undefined && brand !== '') {
                    setProductsFull(data);
                    setProducts(data.filter((product) => product.MARCA === brandsByCatalog.find((brand) => brand.name === catalog).brands.filter((ele) => ele.value === brand)[0].name));
                    setLoaded(true);
                } else {
                    setProductsFull(data);
                    setProducts(data);
                    setLoaded(true);
                }
            }
        });
    }, [catalog, brand]);

    //bring all banner data and filter by brand
    useEffect(() => { getBanners(catalog).then((data) => { setAllBanners(data); }); }, [catalog]);
    function renderBannerData(m, v) {
        if (allBanners !== '') {
            let bannersMarcaArray = allBanners.filter((item) => item.Marca === m);
            let lookup = bannersMarcaArray.find((item) => item.VARIEDAD === v)
            if (v !== null && lookup) { return lookup }
            else {
                const bannersMarca = allBanners.filter((item) => item.Marca === m)[0];
                return bannersMarca;
            }
        }
    }

    // Array of brands that use a custom banner component
    const customBanners = ['Becker', 'Cusqueña', 'Goose Island', 'KM 24.7'];

    //UPDATE Banners
    useEffect(() => {
        // console.log('BANNER', brand);
        if (brand !== undefined && brand !== '') {
            getBanners(catalog).then((data) => {
                console.log('DEBBUG', data)
                setBanner(data.filter((banner) => banner.Marca === brandsByCatalog.find((brand) => brand.name === catalog).brands.filter((ele) => ele.value === brand)[0].name));
            });
        }
        else { setBanner(''); }
    }, [brand, catalog]);

    // render
    return (
        <Layout>
            <div
                // BOTON IMPRIMIR: funtion onClick to trigger print dialog
                onClick={() => { window.print(); handlerCRM('click-print', auth.mail, window.location.pathname, location.latitude + ',' + location.longitude, selectedBrand, selectedCategory, catalog); }}
                className="fixed z-[9999] flex items-center justify-center w-20 h-20 rounded-full shadow-2xl cursor-pointer bg-primary stroke-black bottom-8 right-9 transform animate-appear transition-all duration-700 hover:-translate-y-1 print:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-6"> <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" /> </svg>
            </div>
            {auth[catalog] ? (
                <div className="flex flex-col min-h-screen pb-12 print:pb-0">

                    {typeof banner === 'object' ? (
                        <Fragment>
                            {console.log(banner)}
                            {/* banner generico */}
                            {!customBanners.includes(banner[0].Marca) && <UIBannerItem data={banner} />}
                            {/* banners custom */}
                            {/* {banner[0].Marca === 'B Mix' && <SliderBecker />} */}
                            {banner[0].Marca === 'Becker' && <SliderBecker />}
                            {banner[0].Marca === 'Cusqueña' && <SliderCus />}
                            {banner[0].Marca === 'Goose Island' && <BannerGoose />}
                            {banner[0].Marca === 'KM 24.7' && <BannerKm />}
                        </Fragment>
                    ) : null}

                    {/* catalog logo to refactor */}
                    <UIPickerHeader catalog={catalog} />

                    {/* printble cover */}
                    <div className='w-full relative aspect-[21/23] print:mt-[30px] text-center bg-red-500 hidden print:flex forced-white-printable' style={{ pageBreakAfter: 'always' }}>
                        <img className='absolute top-0 w-full' src='/assets/cover/cover-koe.webp' alt='cover' />
                        {catalog === 'koe' && <img className='absolute bottom-0 w-full' src='/assets/cover/cover-footer.webp' alt='footer' />}
                        {catalog === 'koa' && <img className='absolute bottom-0 w-full' src='/assets/cover/cover-footer-KOA.webp' alt='footer' />}
                        {catalog === 'spmk' && <img className='absolute bottom-0 w-full' src='/assets/cover/cover-footer-SPMK.webp' alt='footer' />}
                    </div>

                    {/* filtros */}
                    <UIFilters>
                        <UIInputOutSelect label={'Marcas'} handleChange={handleBrandChange} options={filteredBrands} value={selectedBrand} placeholder={'Todas las marcas'} />
                        <UIInputOutSelect label={'Categorías'} handleChange={handleCategoryChange} options={categories} value={selectedCategory} placeholder={'Todas las categorías'} />
                    </UIFilters>
                    {/* productos */}
                    {loaded ? (
                        <div className={`grid w-full px-4 md:grid-cols-3 lg:grid-cols-4 xl:max-w-[1256px] mx-auto xl:grid-cols-4 md:flex-row gap-7 ${printDetails ? 'print:grid-cols-1 print:gap-10 sm:grid-cols-1 ' : 'print:grid-cols-3 sm:grid-cols-2 '} `}>
                            {selectedCategory === 'Todas las categorías' || selectedCategory === '' ? products.map((product, index) => {
                                return (
                                    <UICardItem
                                        hasBannerLayout={typeof banner === 'object'}
                                        bannerData={banner}
                                        renderBannerData=
                                        {allBanners !== '' && renderBannerData(product.MARCA, product.VARIEDAD)}
                                        printDetails={printDetails}
                                        index={index}
                                        key={index}
                                        data={product}
                                    />
                                );
                            })
                                : productsFull.filter((product) => product.CATEGORIA === categories.filter((category) => category.value === selectedCategory)[0].name).map((product, index) => { return (<UICardItem key={index} data={product} />); })
                            }
                        </div>
                    ) : (
                        <UISkeleton />
                    )}
                    {error ? <div>sucedio un error</div> : null}

                    {/* last-secition images */}
                    {bannerWhatsapp[catalog].map((item, index) => { return <img key={index} className='hidden w-full h-auto print:flex mb-[20px]' src={item} alt='coverwhatsapp' /> }
                    )}

                    {/* footer */}
                    <div className={`hidden w-full px-8 py-4 text-[8px] leading-none text-center bottom-12 print:hidden`}>
                        AVISO LEGAL Propiedad intelectual e industrial Copyright © 2023, Todos los derechos reservados. Este sitio web y su contenido se encuentran protegidos por la legislación vigente en materia de propiedad intelectual. En ese
                        contexto, AB InBev y/o sus empresas asociadas son titulares o licenciatarios de todos los derechos de propiedad intelectual e industrial de esta web, así como de los elementos contenidos en la misma. Todos los
                        textos, imágenes, gráficas, animaciones, vídeos, piezas de música, sonidos y otros materiales en esta página web están protegidos por los derechos de autor. Estos materiales no deben copiarse para fines comerciales ni
                        para su distribución comercial ni tampoco deben modificarse ni integrarse en otras páginas web sin la autorización de su titular. Por ello, queda expresamente prohibida toda reproducción, distribución, comunicación
                        pública y transformación total o parcial de los contenidos de esta web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización previa y expresa de AB InBev. El acceso al
                        contenido de esta página web no debe interpretarse como una concesión de licencia o derecho sobre las marcas comerciales y/o copyright sin el consentimiento expreso y por escrito de AB InBev. Responsabilidad La
                        información de esta página web puede cambiarse o actualizarse sin notificación previa. No se admite responsabilidad alguna por daños directos o indirectos, reclamaciones de daños y perjuicios, daños consiguientes
                        de cualquier tipo y por cualquier motivo jurídico, que resulten o pudieran resultar del uso de la información incluida en estas páginas web, en particular también por la infección de sus aparatos con virus. La información
                        contenida en esta página web solo sirve para fines informativos.
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center flex-grow">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="w-24 h-24 stroke-slate-400"> <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" /> </svg>
                    <div className="text-2xl font-bold text-slate-400"> No estas autorizado para ver este catálogo </div>
                </div>
            )}
        </Layout>
    );
}
