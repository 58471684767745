export const sliderCus = [
    {
        name: 'golden lager',
        color: '#B09B61',
        sku: '/assets/cus/sku-format-csq-golden.png',
        bg: '#E0B13D',
        logo: '/assets/cus/log-format-golden.svg',
        text: 'HECHA DE 100% PURA MALTA, LÚPULO SAAZ Y UN TRIPLE FILTRADO, QUE LUEGO DE SEGUIR UN DELICADO PROCESO DE ELABORACIÓN, DAN COMO RESULTADO EL EXQUISITO SABOR, AROMA FINO Y PERFECTO COLOR DORADO INTENSO DE UNA CERVEZA ÚNICA.',
        maridaje: {
            color: '#000',
            secondaryColor: '#AA182C',
            data: 'ABV:4,8\nIBU: 20\nDISFRUTAR A: 3°C',
            icons: [
                {
                    label: 'PERFECTA PARA MARIDAR CON comidas cítricas o marinas',
                },
            ],
        },
    },
    {
        name: 'dark lager',
        color: '#2D2A26',
        sku: '/assets/cus/sku-format-CUSQUEÑA-dark-lager.png',
        bg: '#004CD3',
        logo: '/assets/cus/log-format-dark-lager.svg',
        text: 'UNA CERVEZA DE EXQUISITO SABOR DULCE Y COLOR OSCURO INTENSO. HECHA DE 100% PURA MALTA QUE, CON EL TOSTADO PERFECTO Y UN PROCESO EXTRA DE MADURACIÓN, RESALTA LA TEXTURA Y FINO AROMA DE UN LÍQUIDO PREMIUM.',
        maridaje: {
            color: '#fff',
            secondaryColor: '#8E2032',
            labelColor: '#fff',
            data: 'ABV:5,6\nIBU: 12\nDISFRUTAR A: 3°C',
            icons: [
                {
                    image: '/assets/icon-format-clasica.svg',
                    label: 'PERFECTA PARA MARIDAR CON comidas ahumadas y/O postres fuertes bitter. ',
                },
            ],
        },
    },
    {
        name: 'red-lager',
        color: '#8E2032',
        sku: '/assets/cus/sku-format-CUSQUEÑA-red-lager.png',
        bg: '#CEBDEBA',
        logo: '/assets/cus/log-format-red-lager.svg',
        text: 'CON UN CARÁCTER INCONFUNDIBLE PARA LOS PALADARES MÁS EXIGENTES, ESTÁ HECHA CON 100% PURA MALTA CARAMELO QUE LE APORTA UN INTENSO COLOR ROJIZO Y UN SABOR Y AROMA NOTABLEMENTE SUPERIORES.',
        maridaje: {
            color: '#fff',
            secondaryColor: '#fff',
            labelColor: '#8E2032',
            data: 'ABV:5,0\nIBU:20\nDISFRUTAR A: 3°C',
            icons: [
                {
                    image: '/assets/icon-format-roja.svg',
                    label: 'PERFECTA PARA MARIDAR CON sabores hechos a puro fuego como brasas y carnes.',
                },
            ],
        },
    },
];
