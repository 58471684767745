export const slider = [
    {
        name: 'session IPA',
        sku: '/assets/km/sku-format-km-ipa.png',
        bg: '#23423B',
        logo: '/assets/km/log-format-km24-ipa.svg',
        text: 'Refrescante, de amargor marcado otorgado por una combinación de lúpulos patagónicos. Con un intenso aroma cítrico y frutal gracias a la técnica de Dry Hopping.',
        maridaje: {
            icon: '/assets/km/icon-lupulo-km24.svg',
            title: 'Lúpulo Patagónico',
            data: 'ABV:4.5\nIBU: 36',
            text: 'carnes rojas grilladas, hamburguesas gourmet, batatas asadas.',
        },
    },
    {
        name: 'Amber lager ',
        sku: '/assets/km/sku-format-km-amber.png',
        bg: '#79322F',
        logo: '/assets/km/log-format-amber.svg',
        text: 'Refrescante, de amargor marcado otorgado por una combinación de lúpulos patagónicos. Con un intenso aroma cítrico y frutal gracias a la técnica de Dry Hopping.',
        maridaje: {
            icon: '/assets/km/icon-lupulo-km24.svg',
            title: 'MALTAS CARAMELO',
            data: 'ABV:4.5\nIBU: 14.5',
            text: 'carnes rojas grilladas, hamburguesas gourmet, batatas asadas.',

        },
    },
    {
        name: 'Bohemian Pilsener ',
        sku: '/assets/km/sku-format-km-bohemian.png',
        bg: '#D1980C',
        logo: '/assets/km/log-format-bohemian.svg',
        text: 'elevado cuerpo y de sabor maltoso que acompaña muy bien una gran variedad de platos.',
        maridaje: {
            icon: '/assets/km/icon-lupulo-km24.svg',
            title: 'Lúpulo Patagónico',
            data: 'ABV:5.2\nIBU: 18.0',
            text: 'Ideal para carnes blancas, como pollos y pescados con salsas herbales.',
        },
    },
];