import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../context/LoginContext";
import handlerCRM from "../../utils/handlerCRM";

export default function UIBackButton() {
    const navigate = useNavigate();
    const { auth } = useContext(LoginContext);
    const [location] = useState('');
    // const [location, setLocation] = useState('');

    // function GetLocation() {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         setLocation({
    //             latitude: position.coords.latitude,
    //             longitude: position.coords.longitude,
    //         });
    //     });
    // }

    useEffect(() => {
        // GetLocation();
    }, []);
    
    return (
        <div onClick={() => {navigate(-1);
            handlerCRM('back',auth.mail,window.location.pathname,location.latitude + ',' + location.longitude,'Todas las marcas','global','global');
        }} className="cursor-pointer print:hidden">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-10 h-10 ">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                />
            </svg>
        </div>
    );
}
