// eslint-disable-next-line
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import UIButton from '../components/UI/UIButton';
import UIInput from '../components/UI/UIInput';
import UILoader from '../components/UI/UILoader';
import { LoginContext } from '../context/LoginContext';
import { getUsers } from '../utils/getProducts';
import handlerCRM from '../utils/handlerCRM';
import { useNavigate, useParams } from 'react-router-dom';

export default function Login() {
    const navigate = useNavigate();
    const { deeplink } = useParams();
    const { setAuth } = useContext(LoginContext);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [userName, setUserName] = useState('');
    const [location] = useState('');
    // const [location, setLocation] = useState('');
    // function GetLocation() {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         setLocation({
    //             latitude: position.coords.latitude,
    //             longitude: position.coords.longitude,
    //         });
    //     });
    // }

    function handleDeeplinkLoginSubmit(e) {
        getUsers().then((data) => {
            const user = data.filter((user) => user.mail === e);
            if (user.length > 0) {
                setMessage('Usuario encontrado');
                handlerCRM('login-deeplink', e, window.location.pathname, location.latitude + ',' + location.longitude, 'sin marca', 'sin categoria', 'sin catalogo');
                setSuccess(true);
                setUserName(user[0].nombre);
                setAuth(user[0]);
                localStorage.setItem('auth', JSON.stringify(user[0]));
                if (user[0].koe === "TRUE" && user[0].koa === "TRUE" && user[0].spmk === "TRUE") {
                    console.log('koe, koa, spmk access');
                    navigate('/');
                } else if (user[0].koe === "TRUE" && user[0].koa === "TRUE" && user[0].spmk === "FALSE") {
                    console.log('koe, koa access');
                    navigate('/');
                } else if (user[0].koe === "TRUE" && user[0].koa === "FALSE" && user[0].spmk === "FALSE") {
                    console.log('koe access');
                    navigate('/koe');
                } else if (user[0].koe === "FALSE" && user[0].koa === "TRUE" && user[0].spmk === "FALSE") {
                    console.log('koa access');
                    navigate('/koa');
                } else if (user[0].koe === "FALSE" && user[0].koa === "FALSE" && user[0].spmk === "TRUE") {
                    console.log('spmk access');
                    navigate('/spmk');
                }
            } else {
                handlerCRM('login-error-deeplink', email, window.location.pathname, location.latitude + ',' + location.longitude, 'sin marca', 'sin categoria', 'sin catalogo');
                setMessage('Usuario no encontrado');
                setLoading(false);
            }
        });
    }

    function handleSubmit() {
        setLoading(true);
        getUsers().then((data) => {
            const user = data.filter((user) => user.mail === email);
            if (user.length > 0) {
                setMessage('Usuario encontrado');
                handlerCRM('login', email, window.location.pathname, location.latitude + ',' + location.longitude, 'sin marca', 'sin categoria', 'sin catalogo');
                setSuccess(true);
                setTimeout(() => {
                    setUserName(user[0].nombre);
                    setAuth(user[0]);
                    localStorage.setItem('auth', JSON.stringify(user[0]));
                }, 2000);
            } else {
                handlerCRM('login-error', email, window.location.pathname, location.latitude + ',' + location.longitude, 'sin marca', 'sin categoria', 'sin catalogo');
                setMessage('Usuario no encontrado');
                setLoading(false);
            }
        });
    }

    function handleChange(e) {
        setEmail(e.toLowerCase());
    }

    // init
    useEffect(() => {
        if (deeplink) {
            handleDeeplinkLoginSubmit(window.atob(deeplink));
        }
    });

    return (
        <Layout>
            <div
                className="flex flex-col items-center justify-center flex-grow pb-12 overflow-hidden bg-cover"
                style={{ backgroundImage: 'url("/assets/bg/bg-login.jpg")' }}>
                <div className="container">
                    <img
                        src={'/assets/logos/logo-white.svg'}
                        alt="logo"
                        className="h-8 mx-auto mb-12"
                    />

                    <div className="rounded-lg mx-auto gap-4 animate-appear flex flex-col justify-center text-center bg-white shadow-2x1 h-[400px] md:w-[350px] px-6 w-full ">
                        <img
                            src={'/assets/logos/logo.svg'}
                            alt="logo ab"
                            className="h-12  mx-[auto] mb-[2rem]"
                        />
                        {message !== '' && (
                            <div className="uppercase text-xxs">
                                {message === 'Usuario encontrado' ?
                                    <div className="font-bold text-green-700">Usuario encontrado</div>
                                    :
                                    <div className='text-red-500'>usuario no encontrado</div>
                                }
                            </div>
                        )}
                        {!success ? (
                            !loading ? (
                                <React.Fragment>
                                    <form
                                        className="flex flex-col gap-2 animate-appear"
                                        onSubmit={handleSubmit}>
                                        <UIInput
                                            label={'Email'}
                                            type={'email'}
                                            handleChange={handleChange}
                                        />
                                        <UIButton
                                            onClick={(e) => {
                                                handleSubmit(e);
                                            }}>
                                            Iniciar Sesión
                                        </UIButton>
                                    </form>
                                </React.Fragment>
                            ) : (
                                <UILoader label={'validando correo'} />
                            )
                        ) : (
                            <div className="text-2xl font-bold animate-appear">
                                Bienvenido, {userName && userName}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}
