import React from 'react'

function Footer () {
  return (
    <footer className='text-center justify-center text-xxs py-[1rem] flex fixed bottom-0 bg-white w-full z-50 print:hidden'>
      <p className=''>Catálogo Cervecería ABInBev Chile - Derechos reservados/
      Beber con moderación. Prohibida su venta a menores de 18 años.</p>
    </footer>
  )
}

export default Footer
