import { useState } from 'react';
import { slider } from './data';
import BannerGooseItem from './BannerGooseItem';

export default function BannerGoose() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [destroy, setDestroy] = useState(false);

    function nextSlide() {
        if (activeIndex === slider.length - 1) {
            setActiveIndex(0);
            setDestroy(true);
        } else {
            setActiveIndex(activeIndex + 1);
            setDestroy(true);
        }
    }

    function prevSlide() {
        if (activeIndex === 0) {
            setActiveIndex(slider.length - 1);
            setDestroy(true);
        } else {
            setActiveIndex(activeIndex - 1);
            setDestroy(true);
        }
    }

    return (
        <div
            className="relative h-[700px] print:hidden md:h-[400px] overflow-hidden flex items-center transition-all duration-1000"
            style={{
                backgroundColor: `${slider[activeIndex].bg.toString()}`,
            }}
        >
            {/* forced printable backgorund color */}
            <svg className='absolute object-cover' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <rect width="100" height="100" fill={`${slider[activeIndex].bg.toString()}`} />
            </svg>
            <div
                className="absolute print:hidden left-0 z-10 p-5 text-white cursor-pointer top-[100px] md:top-auto animate"
                onClick={() => prevSlide()}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                    />
                </svg>
            </div>
            <div
                className="absolute print:hidden right-0 z-10 p-5 text-white cursor-pointer top-[100px] md:top-auto  animate"
                onClick={() => nextSlide()}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                </svg>
            </div>
            <div className="w-[90%] mx-auto h-[600px] items-center justify-center flex md:h-[500px] print:h-[500px]">
                <BannerGooseItem
                    item={slider[activeIndex]}
                    destroy={destroy}
                    setDestroy={setDestroy}
                />
            </div>
        </div>
    );
}
