import { postCRM } from "./postCrm";

export default function handlerCRM(event = 'pageload', email, path, location = 'sin ubicacion', marca = 'sin marca', category = 'sin categoria', catalog = 'sin catalogo') {
    const now = new Date();
    const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
    const formattedDate = utcNow.toISOString();

    const newbody = {
        "data": {
            "event": event,
            "email": email,
            "start": formattedDate,
            "url": path,
            "end": formattedDate,
            "geolocation": location,
            "marca": marca,
            "categoria": category,
            "catalogo": catalog
        }
    }
    postCRM(newbody)
}