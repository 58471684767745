export const brands = [
    { id: 0, name: 'Todas las marcas', value: 'Todas las marcas', category: 0 },
    { id: 1, name: 'Báltica', value: 'baltica', category: 1 },
    { id: 2, name: 'Becker', value: 'becker', category: 2 },
    // { id: 3, name: 'Becks', value: 'becks', category: 3 },
    { id: 3, name: 'B Mix', value: 'bmix', category: 2 },
    { id: 4, name: 'Budweiser', value: 'budweiser', category: 4 },
    // { id: 5, name: 'Bud Light', value: 'bud-light', category: 4 },
    { id: 6, name: 'Corona', value: 'corona', category: 5 },
    { id: 7, name: 'Cusqueña', value: 'cusquena', category: 4 },
    { id: 7, name: 'KM 24.7', value: 'km-24-7', category: 3 },
    { id: 8, name: 'Goose Island', value: 'goose-island', category: 3 },
    { id: 9, name: 'Hoegaarden', value: 'hoegaarden', category: 3 },
    { id: 10, name: 'Leffe', value: 'leffe', category: 3 },
    { id: 11, name: 'Malta del Sur', value: 'malta-del-sur', category: 2 },
    { id: 12, name: 'Michelob Ultra', value: 'michelob-ultra', category: 5 },
    { id: 13, name: 'Modelo Especial', value: 'modelo', category: 3 },
    { id: 14, name: 'Negra Modelo', value: 'negra-modelo', category: 3 },
    { id: 15, name: 'Pilsen del Sur', value: 'pilsen-del-sur', category: 2 },
    { id: 16, name: 'Quilmes', value: 'quilmes', category: 2 },
    { id: 17, name: 'Stella Artois', value: 'stella-artois', category: 5 },
];

export const brandsByCatalog = [
    {
        name: 'koe',
        brands: [
            { id: 0, name: 'Todas las marcas', value: 'Todas las marcas', category: 0 },
            { id: 1, name: 'Báltica', value: 'baltica', category: 1 },
            { id: 2, name: 'Becker', value: 'becker', category: 2 },
            { id: 4, name: 'Budweiser', value: 'budweiser', category: 4 },
            { id: 6, name: 'Corona', value: 'corona', category: 5 },
            { id: 7, name: 'Cusqueña', value: 'cusquena', category: 4 },
            { id: 7, name: 'KM 24.7', value: 'km-24-7', category: 3 },
            { id: 8, name: 'Goose Island', value: 'goose-island', category: 3 },
            { id: 9, name: 'Hoegaarden', value: 'hoegaarden', category: 3 },
            { id: 10, name: 'Leffe', value: 'leffe', category: 3 },
            { id: 11, name: 'Malta del Sur', value: 'malta-del-sur', category: 2 },
            { id: 12, name: 'Michelob Ultra', value: 'michelob-ultra', category: 5 },
            { id: 15, name: 'Pilsen del Sur', value: 'pilsen-del-sur', category: 2 },
            { id: 16, name: 'Quilmes', value: 'quilmes', category: 2 },
            { id: 17, name: 'Stella Artois', value: 'stella-artois', category: 5 },
        ]
    },
    {
        name: 'koa',
        brands: [
            { id: 0, name: 'Todas las marcas', value: 'Todas las marcas', category: 0 },
            { id: 1, name: 'Báltica', value: 'baltica', category: 1 },
            { id: 2, name: 'Becker', value: 'becker', category: 2 },
            // { id: 3, name: 'Becks', value: 'becks', category: 3 },
            { id: 4, name: 'Budweiser', value: 'budweiser', category: 4 },
            // { id: 5, name: 'Bud Light', value: 'bud-light', category: 4 },
            { id: 6, name: 'Corona', value: 'corona', category: 5 },
            { id: 7, name: 'Cusqueña', value: 'cusquena', category: 4 },
            { id: 7, name: 'KM 24.7', value: 'km-24-7', category: 3 },
            { id: 8, name: 'Goose Island', value: 'goose-island', category: 3 },
            { id: 9, name: 'Hoegaarden', value: 'hoegaarden', category: 3 },
            { id: 10, name: 'Leffe', value: 'leffe', category: 3 },
            { id: 11, name: 'Malta del Sur', value: 'malta-del-sur', category: 2 },
            { id: 12, name: 'Michelob Ultra', value: 'michelob-ultra', category: 5 },
            { id: 13, name: 'Modelo Especial', value: 'modelo', category: 3 },
            { id: 14, name: 'Negra Modelo', value: 'negra-modelo', category: 3 },
            { id: 15, name: 'Pilsen del Sur', value: 'pilsen-del-sur', category: 2 },
            { id: 16, name: 'Quilmes', value: 'quilmes', category: 2 },
            { id: 17, name: 'Stella Artois', value: 'stella-artois', category: 5 },]
    },
    {
        name: 'spmk',
        brands: [
            { id: 0, name: 'Todas las marcas', value: 'Todas las marcas', category: 0 },
            { id: 1, name: 'Báltica', value: 'baltica', category: 1 },
            { id: 2, name: 'Becker', value: 'becker', category: 2 },
            { id: 3, name: 'B Mix', value: 'bmix', category: 2 },
            // { id: 3, name: 'Becks', value: 'becks', category: 3 },
            { id: 4, name: 'Budweiser', value: 'budweiser', category: 4 },
            // { id: 5, name: 'Bud Light', value: 'bud-light', category: 4 },
            { id: 6, name: 'Corona', value: 'corona', category: 5 },
            { id: 7, name: 'Cusqueña', value: 'cusquena', category: 4 },
            { id: 7, name: 'KM 24.7', value: 'km-24-7', category: 3 },
            { id: 8, name: 'Goose Island', value: 'goose-island', category: 3 },
            { id: 9, name: 'Hoegaarden', value: 'hoegaarden', category: 3 },
            { id: 10, name: 'Leffe', value: 'leffe', category: 3 },
            { id: 12, name: 'Michelob Ultra', value: 'michelob-ultra', category: 5 },
            { id: 13, name: 'Modelo', value: 'modelo', category: 3 },
            // { id: 14, name: 'Negra Modelo', value: 'negra-modelo', category: 3 },
            { id: 15, name: 'Pilsen del Sur', value: 'pilsen-del-sur', category: 2 },
            { id: 16, name: 'Quilmes', value: 'quilmes', category: 2 },
            { id: 17, name: 'Stella Artois', value: 'stella-artois', category: 5 },
        ]
    }
];

export const categories = [
    { id: 0, value: 'Todas las categorías', name: 'Todas las categorías' },
    { id: 1, value: 'Value', name: 'Value' },
    { id: 2, value: 'Core', name: 'Core' },
    { id: 4, value: 'Core Plus', name: 'Core Plus' },
    { id: 5, value: 'Premium', name: 'Premium' },
    { id: 3, value: 'Super Premium', name: 'Super Premium' },
];

export const banners = [
    {
        marca: 'Baltica',
        variedad: '',
        skuimage:
            'https://res.cloudinary.com/dkktirn06/image/upload/v1662093417/BANNERS%20KOA%20KOE/baltica-SKUS-banners-KOA-KOE-catalogo-400x400_qcu6m8.png',
        background:
            'https://res.cloudinary.com/dkktirn06/image/upload/v1662093427/BANNERS%20KOA%20KOE/baltica-banners-KOA-KOE-catalogo-1920x400_y6nxcq.png',
        atributos: 'Cheleros de corazón',
        puntosdemercado: 'conocida como La Baltiloca',
        Color: 'Dorada',
        origen: 'Chile',
        ritual: '',
        estilo: '',
        ABV: '',
        IBU: '',
    },
];

export const brandLogos = {
    Baltica:
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661561942/LOGOS%20MARCAS/logo-Baltica-720x720_jrryeb.png',
    Becker: 'https://res.cloudinary.com/dkktirn06/image/upload/v1661563646/LOGOS%20MARCAS/logo-Becker-720x720_kzsceh.png',
    Becks: 'https://res.cloudinary.com/dkktirn06/image/upload/v1661564893/LOGOS%20MARCAS/logo-Becks-720x720_m8c2dl.png',
   'B mix': 'https://res.cloudinary.com/dkktirn06/image/upload/v1661564893/LOGOS%20MARCAS/logo-Becks-720x720_m8c2dl.png',
    Budweiser:
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661568494/LOGOS%20MARCAS/logo-Budweiser-720x720_guwhyh.png',
    'Bud Light':
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661568258/LOGOS%20MARCAS/logo-Budlight-720x720_yjgswz.png',
    Corona: 'https://res.cloudinary.com/dkktirn06/image/upload/v1661561420/LOGOS%20MARCAS/logo-Corona-720x720_p6i2cu.png',
    Cusqueña:
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661568973/LOGOS%20MARCAS/logo-Cusquena-720x720_vfsrek.png',
    'KM 24.7':
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661570118/LOGOS%20MARCAS/Logo_KM_24.7_u5a8a3.png',
    'Goose Island':
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661569285/LOGOS%20MARCAS/Logo_Goose_Island_flspso.png',
    Hoegaarden:
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661569719/LOGOS%20MARCAS/logo-Hoegaarden-720x720_abj2br.png',
    Leffe: 'https://res.cloudinary.com/dkktirn06/image/upsload/v1661570545/LOGOS%20MARCAS/logo-Leffe-720x720_rnmyre.png',
    'Malta del Sur': '',
    'Michelob Ultra':
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661571327/LOGOS%20MARCAS/Logo_Michelob_gjuzsw.png',
    'Modelo Especial':
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661571736/LOGOS%20MARCAS/logo-Modelo-especial-720x720_ldzorq.png',
    'Negra Modelo':
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661627109/LOGOS%20MARCAS/logo-Modelo-negra-720x720_uko5rw.png',
    'Pilsen del Sur':
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661627570/LOGOS%20MARCAS/logo-PDS-720x720_kjx4pj.png',
    Quilmes:
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661627744/LOGOS%20MARCAS/Logo_Quilmes_mg1y05.png',
    'Stella Artois':
        'https://res.cloudinary.com/dkktirn06/image/upload/v1661627877/LOGOS%20MARCAS/logo-Stella-Artois-720x720_whczfd.png',
};

export const bannerWhatsapp = {
    koe: [
        'https://res.cloudinary.com/dkktirn06/image/upload/v1704891583/IMPRIMIR_CUSQUEN%CC%83A_GOLDEN_LAGER_q1awmp.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1704891866/IMPRIMIR_CUSQUEN%CC%83A_GOLDEN_LAGER_1_pgdtur.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1704891583/IMPRIMIR_CUSQUEN%CC%83A_GOLDEN_LAGER_2_oashxd.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1703979636/Becker_Clasica_catalogo_KOE_m7x3vn.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1703979636/Becker_Roja_catalogo_KOE_iigyhi.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1703979636/Becker_Golden_catalogo_KOE_i6ymoc.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1704129452/becker_negra_impreso_yudqpd.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1703990151/Corona_catalogo_KOE_cltzke.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1704800804/Bud_imprimir_KOE_bjcrq5.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1707576043/catalogo_ficha_tecnica_Quilmes_dexgts.jpg',
        'https://res.cloudinary.com/dkktirn06/image/upload/v1704886196/Stella_Artois_Imprimir_KOE_qp9g6f.jpg'
        
    ],
    koa: [],
    spmk: []
}