import { Fragment, useEffect, useState } from 'react';

export default function BannerGooseItem({ item, destroy, setDestroy }) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (destroy) {
            setAnimate(false);
            setTimeout(() => {
                setDestroy(false);
            }, 500);
        } else {
            setAnimate(true);
        }
    }, [destroy, setDestroy]);

    return (
        <div
            className="flex flex-col items-center w-full gap-5 overflow-hidden transition-all duration-75 font-GoodOTCondBold forced-white-printable print:flex-row md:flex-row"
            style={{
                opacity: animate ? 1 : 0,
            }}>
            {/* sku */}
            {animate && (
                <Fragment>
                    <div
                        className="print:h-[180px] my-4 print:animate-none relative md:h-[400px] justify-center h-[220px] hover:scale-100 z-10 transform animate-fade-in-up-nooffset transition-all bg-center duration-1000 flex shrink-0 bg-no-repeat bg-contain print:w-1/3 md:w-1/2"
                    >
                        {/* forced image printable */}
                        <img className='object-contain object-center ' src={item.sku} alt="sku" />
                    </div>
                    <div
                        className="hidden absolute top-24 h-[120px] md:h-[330px] bg-cover right-1/2 md:flex z-[0] bg-opacity-10 left-0 animate-fade-in-right"
                    ></div>

                    {/* info */}
                    <div className="flex flex-col py-8 max-w-[510px]  items-start justify-center md:w-1/2 gap-4"
                        style={{ color: item.textColor }}>
                        <div
                            className="h-[40px] md:h-[80px] transition-all animate-fade-in-right w-[240px] bg-no-repeat flex bg-contain bg-left"
                        >
                            {/* forcerd image printable */}
                            <img className='object-contain object-left' src={item.logo} alt="logo" />
                        </div>
                        <div className="text-xs forced-white-printable animate-delayed-fade-in-left md:text-sm">
                            {item.text.split('\n').map((text, index) => (
                                <p className="mb-3 text-[20px] leading-tight uppercase" key={index}>
                                    {text}
                                </p>
                            ))}
                        </div>
                        {/* info box */}
                        <div className="flex w-full p-2 border-2 text-[18px] leading-tight animate-fade-in-right rounded-2xl"
                            style={{
                                borderColor: item.textColor,
                            }}
                        >
                            <div className="p-2 flex print:text-xs flex-col shrink-0 text-[18px] print:w-[120px] w-[140px] md:w-[140px]">
                                {item.maridaje.data
                                    .split('\n')
                                    .map((text, index) => (
                                        <p className="mb-[0px]" key={index}>
                                            {text}
                                        </p>
                                    ))}
                            </div>
                            <div className="flex flex-col flex-wrap w-full p-2">
                                <div className="uppercase">
                                    principales competidores:
                                </div>
                                {item.maridaje.icons.map((icon, index) => (
                                    <div key={index} className="print:text-[10px]">
                                        {icon.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
            }
        </div >
    );
}
