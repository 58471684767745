import { useState } from 'react';
import { sliderCus } from './dataCus';
import SliderCusItem from './SliderCusItem';

export default function SliderCus() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [destroy, setDestroy] = useState(false);

    function nextSlide() {
        if (activeIndex === sliderCus.length - 1) {
            setActiveIndex(0);
            setDestroy(true);
        } else {
            setActiveIndex(activeIndex + 1);
            setDestroy(true);
        }
    }

    function prevSlide() {
        if (activeIndex === 0) {
            setActiveIndex(sliderCus.length - 1);
            setDestroy(true);
        } else {
            setActiveIndex(activeIndex - 1);
            setDestroy(true);
        }
    }

    return (
        <div className="relative bg-[#EBDEBA] md:h-[400px] print:hidden overflow-hidden flex items-center transition-all duration-1000 forced-white-printable">
            <div
                className="absolute top-0 bottom-0 left-0 right-0 bg-cover mix-blend-multiply"
                style={{
                    backgroundImage: 'url(/assets/cus/paper.webp)',
                }}></div>
            <div
                className="absolute print:hidden left-0 z-10 p-5 text-white cursor-pointer top-[100px] md:top-auto z-x10 animate"
                onClick={() => prevSlide()}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                    />
                </svg>
            </div>
            <div
                className="absolute right-0 z-50 p-5 text-white cursor-pointer print:hidden"
                onClick={() => nextSlide()}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                </svg>
            </div>
            <div className="w-[90%] mx-auto h-full py-2 items-center justify-center flex ">
                <SliderCusItem
                    item={sliderCus[activeIndex]}
                    destroy={destroy}
                    setDestroy={setDestroy}
                />
            </div>
        </div>
    );
}
