import React, { createContext, useState, useMemo } from 'react';
const LoginContext = createContext({
    auth: {},
    setAuth: () => {},
});

let datauser = JSON.parse(localStorage.getItem('auth'));
const LoginContextProvider = ({ children }) => {
    const [auth, setAuth] = useState(datauser ? datauser : null); //{ logged: false, user: 1, role: 1 }
    const value = useMemo(() => ({ auth, setAuth }), [auth]);
    return (
        <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
    );
};

const LoginContextConsumer = LoginContext.Consumer;
export { LoginContext, LoginContextProvider, LoginContextConsumer };
