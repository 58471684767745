import { Fragment, useEffect, useState } from 'react';

export default function SliderBeckerItem({ item, destroy, setDestroy }) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (destroy) {
            setAnimate(false);
            setTimeout(() => {
                setDestroy(false);
            }, 500);
        } else {
            setAnimate(true);
        }
    }, [destroy, setDestroy]);

    return (
        <div
            className="flex flex-col items-center w-full gap-5 overflow-hidden transition-all duration-75 print:flex-row md:flex-row"
            style={{
                opacity: animate ? 1 : 0,
            }}>
            {/* sku */}
            {animate && (
                <Fragment>
                    <div
                        className="print:h-[200px] print:animate-none relative md:h-[400px] justify-center h-[220px] hover:scale-100 z-10 transform animate-delayed-fade-in-up transition-all duration-1000 flex shrink-0 bg-no-repeat bg-contain bg-bottom print:w-1/3 md:w-1/2"
                    >
                        {/* forced image printable */}
                        <img className='object-contain object-center' src={item.sku} alt="sku" />
                    </div>
                    <div
                        className="hidden absolute top-24 h-[100px] md:h-[330px] bg-cover right-1/2 md:flex z-[0] bg-opacity-10 left-0 animate-fade-in-right"
                    ></div>

                    {/* info */}
                    <div className="flex flex-col py-8 max-w-[400px] items-start justify-center md:w-1/2 gap-4 text-white">
                        <div
                            className="h-[40px] md:h-[80px] transition-all animate-fade-in-right w-[240px] bg-no-repeat flex bg-contain bg-left"
                        // style={{
                        //     backgroundImage: `url(${item.logo})`,
                        // }}
                        >
                            {/* forcerd image printable */}
                            <img className='object-contain object-center' src={item.logo} alt="logo" />
                        </div>
                        <div className="text-xs forced-white-printable animate-delayed-fade-in-left md:text-sm">
                            {item.text.split('\n').map((text, index) => (
                                <p className="mb-3" key={index}>
                                    {text}
                                </p>
                            ))}
                        </div>
                        {/* info box */}
                        <div className="flex w-full border-2 border-white animate-fade-in-right rounded-2xl">
                            <div className="p-2 flex forced-white-printable print:text-xs flex-col border-r-2 shrink-0 text-[14px] print:w-[120px] w-[140px] md:w-[140px] border-r-white">
                                {item.maridaje.data
                                    .split('\n')
                                    .map((text, index) => (
                                        <p className="mb-[0px]" key={index}>
                                            {text}
                                        </p>
                                    ))}
                            </div>
                            <div className="flex flex-col flex-wrap w-full p-2">
                                <div className="text-xs tracking-widest uppercase forced-white-printable">
                                    maridaje:
                                </div>
                                <div className="flex flex-wrap gap-3 print:gap-2 forced-white-printable">
                                    {item.maridaje.icons.map((icon, index) => (
                                        <div
                                            className="flex items-center justify-center flex-col print:w-[75px] w-[95px]"
                                            key={index}>
                                            <div
                                                className="h-[80px] flex justify-center items-center relative w-[70px] bg-no-repeat bg-contain bg-center"

                                            >
                                                {/* forced printable  */}
                                                <img className='object-contain object-center' src={icon.image} alt="icon" />
                                            </div>
                                            <div className="text-xs print:text-[10px] print:text-[#fff] text-center forced-white-printable">
                                                {icon.label}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}
