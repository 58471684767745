import UISkeletonCell from './UISkeletonCell';

export default function UISkeleton() {
    return (
        <div className="grid w-full px-4 sm:print:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:max-w-[1256px] mx-auto xl:grid-cols-4 md:flex-row gap-7 ">
            <UISkeletonCell />
            <UISkeletonCell />
            <UISkeletonCell />
            <UISkeletonCell />
            <UISkeletonCell />
        </div>
    );
}
