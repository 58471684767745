import React from 'react';
import Layout from '../components/Layout';
import { useParams } from 'react-router-dom';

export default function Piramide() {
    const { catalog } = useParams();

    return (
        <Layout>
            <div className="container flex flex-col min-h-screen pb-12">
                <div className="pb-10 mx-auto text-3xl italic font-bold uppercase">
                    Pirámide de Precios
                </div>
                {catalog === 'spmk' && (
                    <img
                        className="mx-auto"
                        src="/assets/piramideSPMK.png"
                        alt="piramide"
                    />
                )}
                {catalog !== 'spmk' && (
                    <img
                        className="mx-auto"
                        src="/assets/piramide.jpg"
                        alt="piramide"
                    />
                )}
            </div>
        </Layout>
    );
}
