import React, { createContext, useState, useMemo } from 'react';
const LegalContext = createContext({
    legal: {},
    setLegal: () => {},
});

let legals = JSON.parse(localStorage.getItem('cookies'));
const LegalContextProvider = ({ children }) => {
    const [legal, setLegal] = useState(legals ? legals : null); //{ logged: false, user: 1, role: 1 }
    const value = useMemo(() => ({ legal, setLegal }), [legal]);
    return (
        <LegalContext.Provider value={value}>{children}</LegalContext.Provider>
    );
};

const LegalContextConsumer = LegalContext.Consumer;
export { LegalContext, LegalContextProvider, LegalContextConsumer };
