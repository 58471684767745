import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from '../..';
import UIModalItem from './UIModalItem';

export default function UICardItem({ printDetails, data, index, hasBannerLayout = false, renderBannerData }) {
    const { catalog } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Fragment>
            {isModalOpen && <Modal><UIModalItem data={data} catalog={catalog} closeModal={() => setIsModalOpen(false)} /></Modal>}
            {/* {console.log('dataBANNER', renderBannerData)} */}
            <div
                onClick={() => setIsModalOpen(true)}
                id={`card-product-${index}`}
                className={`flex   w-full mb-20 print:mb-4 ${printDetails ? 'flex-col print:flex-row print:h-[250px] gap-2 justify-start items-start' : 'flex-col items-center justify-center'}`}
                style={{ pageBreakBefore: hasBannerLayout ? (index - 3) % 6 === 0 ? 'always' : 'auto' : index !== 0 && index % 6 === 0 ? 'always' : 'auto', }}>
                {/* imagen y stock */}
                <div className={`relative w-full aspect-square p-6 mb-4 print:mb-0 flex items-center justify-center md:w-full rounded-2xl hover:from-gray-200 to-transparent bg-gradient-to-t ${printDetails ? 'h-[250px] print:h-[200px] print:w-[200px]' : 'h-[250px] print:h-[150px]'}`}>
                    {data['IN STOCK'] === 'no' && (<div className="absolute top-0 bottom-0 left-0 z-30 flex items-center justify-center w-full text-xl font-bold text-white bg-black bg-opacity-70 rounded-xl"> out of stock </div>)}
                    {data.IMAGEN && (<img src={data.IMAGEN} alt={data.DESCRIPCION} className="flex h-full transition-all bg-center bg-no-repeat object-fit-contain hover:scale-110" />)}
                </div>

                {/* INFORMACION */}
                <div className="flex flex-col w-full">
                    {/* DESCRIPCION y GRADOS */}
                    <div className={`flex shrink-0 flex-col w-full ${printDetails ? 'print:gap-2 print:flex-row' : ''}`}>
                        <div className='flex flex-col w-full'>
                            <div className="flex items-start leading-[1.2] w-full italic border-b-2 border-yellow-500  md:h-[6rem] md:items-center md:flex-row justify-between">
                                {data.DESCRIPCION && (<div className="flex font-bold text-[13px] print:h-[30px] lg:text-lg text-gray md:w-[80%] uppercase"> {data.DESCRIPCION} </div>)}
                                {data.GRADOS !== null && (<div className="flex text-base font-medium text-right shrink-0 text-red"> {data.GRADOS} °Alc </div>)}
                            </div>
                            {/* TAMAÑO Y GRADOS DE ALCOHOL */}
                            <div className="flex items-center relative italic h-[48px] after:block after:absolute print:h-[30px] mb-2 border-b-2 border-yellow-500">
                                {data.TAMAÑO === 'LITRO'
                                    ? <div className="card-product--size__text"> LITRO </div>
                                    : <div className="font-bold  text-[15px] text-gray"> {data.TAMAÑO} <span className="text-xs">cc.</span> </div>
                                }
                            </div>
                            {/* PALLETS y EAN */}
                            <div className="flex justify-between text-xs font-medium text-gray">
                                {data['CAJAS POR PALLET'] && (<div className=""> {data['CAJAS POR PALLET']} cajas por pallet </div>)}
                                <div className="uppercase ">
                                    {catalog === 'koe' && `COD KOE: ${data['COD KOE']}`}
                                    {catalog === 'koa' && `COD KOA : ${data['COD KOA']}`}
                                    {catalog === 'spmk' && (<div className="flex flex-col items-end justify-end"> <div>{data['EAN (INTERMEDIO)']}</div> <div>COD KOA : {data['COD KOA']}</div> <div>COD KOE : {data['COD KOE']}</div> </div>)}
                                </div>
                            </div>
                            {printDetails && renderBannerData !== '' &&
                                <div className={`items-center relative w-full italic gap-2 mt-1 py-1 text-[12px] border-t-2 border-yellow-500 justify-between ${printDetails ? 'hidden print:flex' : ''}`}>
                                    <div className='font-bold'>Competidores:</div><div className='text-right'>{renderBannerData['Principales Competidores']}</div>
                                </div>
                            }
                        </div>

                        {printDetails && renderBannerData !== '' &&
                            <div className={`items-center relative italic gap-1 flex-col pb-2 text-[12px] w-full ${printDetails ? 'hidden print:flex' : ''}`}>
                                <div className={`items-center relative w-full italic gap-2 col py-1 text-[12px] flex justify-between`}>
                                    <div className='font-bold'>Color:</div><div>{renderBannerData['Color']}</div>
                                </div>
                                <div className={`items-center relative w-full italic gap-2 py-1 text-[12px] border-t-2 border-yellow-500 flex justify-between`}>
                                    <div className='font-bold'>Estilo</div><div>{renderBannerData['Estilo']}</div>
                                </div>
                                <div className={`items-center relative w-full italic gap-2 py-1 text-[12px] border-t-2 border-yellow-500 flex justify-between`}>
                                    <div className='font-bold'>IBU</div><div>{renderBannerData['IBU']}</div>
                                </div>
                                <div className={`items-center relative w-full italic gap-2 py-1 text-[12px] border-t-2 border-yellow-500 flex justify-between`}>
                                    <div className='font-bold'>Origen:</div><div>{renderBannerData['Origen']}</div>
                                </div>
                            </div>
                        }
                    </div>

                    {catalog === 'spmk' &&
                        <div className="mx-auto mt-[2.5rem] print:mt-[8px] px-[15%] print:px[0px] py-[0]">
                            {data['EAN (INTERMEDIO)'] && (<img src={`https://barcode.tec-it.com/barcode.ashx?data=${data['EAN (INTERMEDIO)'].toString()}&code=Code128&translate-esc=on`} alt="img prdducto" className="mx-auto mt-[2.5rem] print:mt-0 px-[15%] print:px-0 py-[0]" />)}
                        </div>
                    }

                    {printDetails && renderBannerData !== '' &&
                        <div className={`p-3 flex flex-col text-[12px] rounded-lg border w-full ${printDetails ? 'hidden print:flex' : ''}`}>
                            <div>{renderBannerData['Atributos']}</div>
                            <div className='font-bold'>Argumento venta:</div><div>{renderBannerData['Argumento de Venta']}</div>
                            <div><span className='font-bold'>Maridaje: </span> {renderBannerData['Maridaje']}</div>

                        </div>
                    }
                </div >


            </div >
        </Fragment >
    );
}
