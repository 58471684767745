import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { LoginContextProvider } from './context/LoginContext';
import Navigation from './navigation/Navigation';
import { createPortal } from 'react-dom';
import TagManager from 'react-gtm-module';
import { GTM_ID } from './enviroment/config';
import { LegalContextProvider } from './context/LegalContext';

const modalRoot = document.querySelector('#root-modal');
const tagManagerArgs = { gtmId: GTM_ID };
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <LegalContextProvider>
        <LoginContextProvider>
            <Navigation />
        </LoginContextProvider>
    </LegalContextProvider>
);

export const Modal = ({ children }) => createPortal(children, modalRoot);
