import axios from 'axios';

// traer todo
export const getProducts = async (id) => {
    try {
        const { data } = await axios.get(
            `https://sharepoint-middleware-production-fcdf.up.railway.app/catalog?id=${id}`
        );
        return data;
    } catch (error) {
        return error;
    }
};

export const getBanners = async (id) => {
    try {
        const { data } = await axios.get(
            `https://sharepoint-middleware-production-fcdf.up.railway.app/banner?id=${id}`
        );
        return data;
    } catch (error) {
        return error;
    }
};

export const getUsers = async (email) => {
    try {
        const { data } = await axios.get(
            `https://sharepoint-middleware-production-fcdf.up.railway.app/users`
        );
        return data;
    } catch (error) {
        return error;
    }
};
