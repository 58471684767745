import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from '../hooks/ScrollTop';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { LoginContext } from '../context/LoginContext';
import { LegalContext } from '../context/LegalContext';
import Welcome from '../views/Welcome';
import Catalog from '../views/Catalog';
import Login from '../views/Login';
import Piramide from '../views/Piramide';
import Exito from '../views/Exito';
import Cookies from '../components/Cookies';
import EjecuciónCooler from '../views/EjecucionCoolerSpmk';
import PlanGary from '../views/Gary';

export default function Navigation() {
    const { auth } = useContext(LoginContext);
    const { legal } = useContext(LegalContext);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('cookies') === 'true') {
            setCookiesAccepted(true);
        }
    }, [legal]);

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {auth === null ? (
                    <Fragment>
                        <Route path="/*" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/login/:deeplink" element={<Login />} />
                    </Fragment>
                ) : (
                    <Fragment>
                        <Route path="/:catalog" element={<Catalog />} />
                        <Route path="/:catalog/exito" element={<Exito />} />
                        <Route path="/:catalog/piramide" element={<Piramide />} />
                        <Route path="/:catalog/plan-gary" element={<PlanGary />} />
                        <Route path="/:catalog/ejecucion-cooler" element={<EjecuciónCooler />} />
                        <Route path="/:catalog/:brand" element={<Catalog />} />
                        <Route path="/*" element={<Welcome />} />

                        {/* Grilla_semana_8KOA */}
                    </Fragment>
                )}
            </Routes>
            {!cookiesAccepted && <Cookies />}
        </BrowserRouter>
    );
}
