import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import UIButton from '../components/UI/UIButton';

export default function Exito() {
    let { catalog } = useParams();

    function handleDownload() {
        if (catalog === 'spmk') {
            return ' https://anheuserbuschinbev-my.sharepoint.com/:b:/g/personal/katherine_velasquez_cerchile_cl/ETXR33o6S8NFmX0i7MIoKqIBgtYlF1nvTzRpfQZE5h5qjA?e=eMzJmw';
        } else if (catalog === 'koa') {
            return 'https://anheuserbuschinbev-my.sharepoint.com/:b:/g/personal/katherine_velasquez_cerchile_cl/EbUWHocbQ2xFgD-uHW_lGQUBma5e4S_j3MAtAc9hq1lg-w?e=j8EERL';
        } else if (catalog === 'koe') {
            return 'https://anheuserbuschinbev-my.sharepoint.com/:b:/g/personal/katherine_velasquez_cerchile_cl/EbUWHocbQ2xFgD-uHW_lGQUBma5e4S_j3MAtAc9hq1lg-w?e=j8EERL';
        } else {
            return '/catalogo';
        }
    }
    return (
        <Layout>
            <div className="container flex flex-col items-center min-h-screen gap-4 pb-12">
                <div className="mx-auto text-3xl italic font-bold uppercase">
                    Foto del Éxito
                </div>
                <UIButton
                    onClick={() => {
                        window.open(handleDownload(), '_blank');
                    }}>
                    <div className='flex items-center gap-2'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                            />
                        </svg>
                        Descargar PDF
                    </div>
                </UIButton>
                <img
                    className="mx-auto"
                    src="https://www.ab-inbev.com/img/redesign/agegate-2021.jpg"
                    alt="piramide"
                />
            </div>
        </Layout>
    );
}
