export default function UIPickerHeader({ catalog }) {
    return (
        <div className="flex flex-col justify-center gap-4 p-4 print:scale-75 print:mb-0 print-mx-0 print:gap-2 print:items-center print:justify-center">
            <img className="h-10" src="/assets/logos/catalogo.svg" alt="logo" />
            {catalog !== 'spmk' ? (
                <img
                    className="h-8 mx-auto"
                    src={`/assets/logos/logo-${catalog}.png`}
                    alt="logo"
                />
            ) : null}
        </div>
    );
}
