export default function UIFilters({ children }) {
    return (
        <div className="flex gap-4 py-6 bg-white print:hidden">
            <div className="container">
                <div className="flex gap-4 max-w-[500px] mx-auto">
                    {children}
                </div>
            </div>
        </div>
    );
}
