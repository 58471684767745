import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import { LoginContext } from '../context/LoginContext';
import TagManager from 'react-gtm-module';
// import UIModalLocation from '../components/UI/UIModalLocation';
// import useGeoLocation from '../hooks/useGeolocation';
import handlerCRM from '../utils/handlerCRM';

export default function Welcome() {
    const { auth } = useContext(LoginContext);
    const navigate = useNavigate();
    // const [location, setLocation] = useState('');
    const [location] = useState('');

    // useGeoLocation();

    // function GetLocation() {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         setLocation({
    //             latitude: position.coords.latitude,
    //             longitude: position.coords.longitude,
    //         });
    //     });
    // }

    useEffect(() => {
        // GetLocation();
    }, []);

    return (
        <Layout>
            {/* {localStorage.getItem('permission_status') !== 'ok' && (
                <UIModalLocation />
            )} */}
            <div className="flex flex-col flex-grow h-full pb-12 ">
                <div className="flex-col items-center justify-center hidden gap-4 p-12 text-lg font-light text-center md:flex">
                    {auth.koa  ||
                    auth.spmk  ||
                    auth.koe  ? (
                        <div>Catálogos disponibles para ti</div>
                    ) : (
                        <div>
                            Tu usuario no tiene acceso al catálogo
                            <div
                                className="flex justify-center w-full p-4 mt-8 rounded cursor-pointer bg-primary md:w-auto hover:bg-gray-100"
                                onClick={() => {
                                    localStorage.removeItem('auth');
                                    window.location.href = '/';
                                }}>
                                Regresar al Inicio
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex flex-col flex-grow w-full h-full font-bold uppercase md:flex-row md:text-5xl">
                    {auth.koa ? (
                        <div
                            className="flex items-center justify-center flex-grow w-full min-h-full text-white transition-all duration-700 transform bg-red-500 bg-cover cursor-pointer hover:saturate-100 saturate-0"
                            onClick={() => {
        handlerCRM('click',auth.mail,window.location.pathname,location.latitude + ',' + location.longitude,'sin marca','sin categoria','koa');
                                navigate('/koa');
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'seleccionar_catalogo',
                                        categoria: 'Todas las categorías',
                                        catalogo: 'koa',
                                        userId: auth.mail,
                                        geolocation: location.latitude + ',' + location.longitude
                                    },
                                });
                            }}
                            style={{
                                backgroundImage:
                                    'url("https://media.elmostrador.cl/2015/08/00_embotelladora-andina_5497.png")',
                            }}>
                            koa
                        </div>
                    ) : null}

                    {auth.spmk ? (
                        <div
                            className="flex items-center justify-center flex-grow w-full min-h-full text-white transition-all duration-700 transform bg-red-500 bg-cover cursor-pointer hover:saturate-100 saturate-0"
                            onClick={() => {
        handlerCRM('click',auth.mail,window.location.pathname,location.latitude + ',' + location.longitude,'sin marca','sin categoria','spmk');
                                navigate('/spmk');
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'seleccionar_catalogo',
                                        categoria: 'Todas las categorías',
                                        catalogo: 'spmk',
                                        userId: auth.mail,
                                        geolocation: location.latitude + ',' + location.longitude
                                    },
                                });
                            }}
                            style={{
                                backgroundImage:
                                    'url("https://res.cloudinary.com/dkktirn06/image/upload/v1675051211/fondo_SPMK_catalogo_online_welcome_shgsaw.jpg")',
                            }}>
                            spmk
                        </div>
                    ) : null}

                    {auth.koe ? (
                        <div
                            className="flex items-center justify-center flex-grow w-full min-h-full text-white transition-all duration-700 transform bg-red-500 bg-cover cursor-pointer hover:saturate-100 saturate-0"
                            onClick={() => {
        handlerCRM('click',auth.mail,window.location.pathname,location.latitude + ',' + location.longitude,'sin marca','sin categoria','koe');
                                navigate('/koe');
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'seleccionar_catalogo',
                                        categoria: 'Todas las categorías',
                                        catalogo: 'koe',
                                        userId: auth.mail,
                                        geolocation: location.latitude + ',' + location.longitude
                                    },
                                });
                            }}
                            style={{
                                backgroundImage:
                                    'url("https://www.embonor.cl/wp/wp-content/uploads/2021/06/03-la-empresa-historia-de-embonor-contenido-2000.jpg")',
                            }}>
                            koe
                        </div>
                    ) : null}
                </div>
            </div>
        </Layout>
    );
}
