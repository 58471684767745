import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UISlider from './UISlider';

export default function UIBannerItem({ data }) {
    const { catalog } = useParams();

    return catalog === 'spmk' ? (
        <UISlider>
            {data.map((banner, index) => {
                return <UIBannerItemMutableSpmk banner={banner} key={index} />;
            })}
        </UISlider>
    ) : (
        <UISlider>
            {data.map((banner, index) => {
                return <UIBannerItemMutable banner={banner} key={index} />;
            })}
        </UISlider>
    );
}

function UIBannerItemMutableSpmk({ banner, index }) {
    return (
        <div
            key={index}
            className="relative mb-12 overflow-hidden bg-center bg-cover animate-appear print:rounded-xl forced-white-printable"
        // style={{
        //     backgroundImage: `url(${banner['link de background']})`,
        // }}
        >
            {/* forced backgroin pritnable image */}
            <img className='absolute z-[-1] object-cover h-full' src={banner['link de background']} alt="background" />
            <div className="max-w-[90%] md:max-w-[80%] print:grid-cols-5  lg:max-w-[80%] xl:max-w-[70%] 2xl:max-w-[60%] mx-auto grid py-4 gap-6 lg:grid-cols-2">
                <div
                    className="w-full print:col-span-2 bg-center bg-no-repeat bg-contain print:h-[280px] h-[420px] "
                    style={{
                        backgroundImage: `url(${banner['link sku baner']})`,
                    }}
                />
                <div className="flex flex-col items-center print:col-span-3 md:my-auto">
                    <div className="flex mx-auto md:my-auto w-full h-auto lg:w-[500px] flex-col overflow-hidden bg-white shadow-xl shadow-1x2 rounded-xl ">
                        <div className="p-4 font-bold text-white uppercase bg-blue">
                            <div className="opacity-40">ATRIBUTOS</div>
                            <div className="text-[24px]">
                                {banner.Atributos}
                            </div>
                        </div>
                        <p className="text-blue p-4 pb-0 font-bold leading-[1.2] ">
                            ARGUMENTO DE VENTA
                        </p>
                        <div className="grid grid-cols-2 sm:grid-cols-3 print:grid-cols-3 text-xss opacity-70 text-gray my-[.5rem] leading-[1.2]">
                            <div className="flex flex-col col-start-2 gap-2 p-4 sm:col-start-1">
                                <div className="flex flex-col gap-2 text-sm print:text-xs">
                                    {banner['Puntos mercado']}
                                </div>
                                <p className="text-slate-500 text-xs font-bold leading-[1.2] ">
                                    PUNTOS DEL <br /> MERCADO
                                </p>
                            </div>
                            <div className="flex flex-col col-start-1 row-start-1 gap-2 p-4 font-bold sm:col-start-2 text-slate-600 print:text-xs">
                                <p className="">Color : {banner.Color}</p>
                                <p className="">Estilo : {banner.Estilo}</p>
                                <p className="">Origen : {banner.Origen}</p>
                            </div>
                            <div className="flex flex-col col-start-1 gap-2 p-4 font-bold print:col-start-3 sm:col-start-3 text-slate-600 print:text-xs">
                                <p className="">ABV : {banner.ABV}</p>
                                <p className="">Ritual : {banner.Ritual}</p>
                                <p className="">IBU : {banner.IBU}</p>
                            </div>
                        </div>
                    </div>
                    {banner['Website'] && (
                        <a
                            href={banner['Website']}
                            target={'_blank'}
                            rel="noreferrer"
                            className="flex items-center gap-2 p-2 px-4 mt-3 ml-auto text-xs font-bold tracking-wide text-white rounded-lg print:hidden hover:animate-pulse bg-blue">
                            CONOCE MÁS
                            <div className="block w-2 h-2 transform rotate-45 border-t border-r border-white " />
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}

function UIBannerItemMutable({ banner, index }) {
    const [bullets, setBullets] = useState([]);
    const [type, setType] = React.useState('');

    useEffect(() => {
        setBullets(banner['Argumento de Venta'].split('\n'));
        if (banner.VIDEO !== '') {
            setType('video');
        } else {
            setType('image');
        }
    }, [banner]);

    return (
        <div
            key={index}
            className="relative flex mb-12 bg-center bg-cover animate-appear forced-white-printable"
        // style={{
        //     backgroundImage: `url(${banner['link de background']})`,
        // }}
        >
            {/* forceg backgroinf printable image */}
            <img className='absolute z-[-1] object-cover h-full' src={banner['link de background']} alt="background" />
            <div
                className={`max-w-[90%] md:max-w-[80%] lg:max-w-[80%] xl:max-w-[70%] 2xl:max-w-[60%] print:grid-cols-5 mx-auto grid py-4 gap-6 ${type === 'image' ? 'lg:grid-cols-2' : 'lg:grid-cols-1'
                    }`}>
                {type === 'image' ? (
                    <div
                        className="w-full bg-center relative print:col-span-2 flex justify-center items-center bg-no-repeat bg-contain print:h-[300px] h-[420px] "
                    // style={{
                    //     backgroundImage: `url(${banner['link sku baner']})`,
                    // }}
                    >
                        {/* forced printable image */}
                        <img className='absolute object-contain' src={banner['link sku baner']} alt="background" />
                    </div>
                ) : (
                    <div className="flex items-center w-full print:col-span-2 justify-center h-[420px]">
                        <video
                            className="flex h-full aspect-video"
                            autoPlay
                            muted
                            loop
                            src={banner.VIDEO}
                        />
                        {/* <div className="flex aspect-video">{banner.VIDEO}</div> */}
                    </div>
                )}
                {type === 'image' ? (
                    <div className="flex flex-col items-center print:col-span-3 print:w-full md:my-auto">
                        <div className="flex mx-auto  w-full h-auto lg:w-[500px] flex-col overflow-hidden bg-white shadow-xl rounded-xl ">
                            <div className="p-4 font-bold text-white uppercase bg-blue forced-white-printable ">
                                <div className="opacity-40">ATRIBUTOS</div>
                                <div className="text-[24px]">
                                    {banner.Atributos}
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-2 print:grid-cols-2 text-xss opacity-70 text-gray my-[.5rem] leading-[1.2]">
                                <div className="flex flex-col gap-2 p-4 border-r-2 sm:border-r-blue">
                                    <p className="text-blue font-bold leading-[1.2] print:text-sm ">
                                        ARGUMENTO DE VENTA
                                    </p>
                                    <ul className="flex flex-col gap-2 text-sm print:text-xs">
                                        {bullets.map((bullet, index) => (
                                            <li key={index} className="">
                                                {bullet}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="flex flex-col gap-2 p-4">
                                    <p className="text-blue font-bold leading-[1.2]  print:text-sm">
                                        ORIGEN
                                    </p>
                                    <p className="mb-2 text-sm print:text-xs">
                                        {banner.Origen}
                                    </p>
                                    <p className="text-blue font-bold leading-[1.2] print:text-sm ">
                                        PRINCIPALES COMPETIDORES
                                    </p>
                                    <p className="text-sm print:text-xs">
                                        {banner['Principales Competidores']}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {banner['Website'] && (
                            <a
                                href={banner['Website']}
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center gap-2 p-2 px-4 mt-3 ml-auto text-xs font-bold tracking-wide text-white rounded-lg print:hidden hover:animate-pulse bg-blue forced-white-printable">
                                CONOCE MÁS
                                <div className="block w-2 h-2 transform rotate-45 border-t border-r border-white " />
                            </a>
                        )}
                    </div>
                ) : null}
            </div>

        </div>
    );
}
