import { Fragment, useEffect, useState } from 'react';

export default function BannerKmItem({ item, destroy, setDestroy }) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (destroy) {
            setAnimate(false);
            setTimeout(() => {
                setDestroy(false);
            }, 500);
        } else {
            setAnimate(true);
        }
    }, [destroy, setDestroy]);

    return (
        <div
            className="flex flex-col items-center w-full gap-5 overflow-hidden transition-all duration-75 forced-white-printable print:flex-row md:flex-row"
            style={{
                opacity: animate ? 1 : 0,
            }}>
            {/* sku */}
            {animate && (
                <Fragment>
                    <div
                        className="print:h-[180px] my-4 print:animate-none relative md:h-[400px] justify-center h-[220px] hover:scale-100 z-10 transform animate-fade-in-up-nooffset transition-all bg-center duration-1000 flex shrink-0 bg-no-repeat bg-contain print:w-1/3 md:w-1/2"
                    >
                        {/* forced image printable */}
                        <img className='object-contain object-center' src={item.sku} alt="sku" />
                    </div>
                    <div
                        className="hidden absolute top-24 h-[120px] md:h-[330px] bg-cover right-1/2 md:flex z-[0] bg-opacity-10 left-0 animate-fade-in-right"
                    ></div>

                    {/* info */}
                    <div className="flex flex-col py-8 max-w-[510px] items-start justify-center md:w-1/2 gap-4 text-white">
                        <div
                            className="h-[40px] md:h-[80px] transition-all animate-fade-in-right w-[240px] bg-no-repeat flex bg-contain bg-left"
                        >
                            {/* forcerd image printable */}
                            <img className='object-contain object-left' src={item.logo} alt="logo" />
                        </div>
                        <div className="text-xs forced-white-printable animate-delayed-fade-in-left md:text-sm">
                            {item.text.split('\n').map((text, index) => (
                                <p className="mb-3" key={index}>
                                    {text}
                                </p>
                            ))}
                        </div>
                        {/* info box */}
                        <div className="flex flex-col w-full border-2 border-white md:flex-row print:flex-row animate-fade-in-right ">
                            <div className='flex border-b-2 border-white md:border-b-0'>
                                <div className='flex h-full items-center relative w-[120px]  md:w-[90px] justify-center p-4 border-r-2 border-white'>
                                    <img className='object-contain object-center ' src={item.maridaje.icon} alt='icon' /></div>
                                <div className='flex flex-col w-full'>
                                    <div className="p-0 flex h-full justify-start items-start flex-col font-AmsiProBlack forced-white-printable w-full print:text-xs  shrink-0 text-[14px]">
                                        <div className='flex flex-row items-center justify-center w-full h-full p-3 uppercase border-b-2 border-white md:border-r-2 font-AmsiProBlack '>{item.maridaje.title}</div>
                                        <div className='relative flex flex-row w-full'>
                                            {item.maridaje.data
                                                .split('\n')
                                                .map((text, index) => (
                                                    <div className="flex w-full p-3 border-r-2 border-white " key={index}>
                                                        {text}
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full p-3 grow">
                                <div className="uppercase font-AmsiProBlack">
                                    maridaje:
                                </div>
                                <div className="flex flex-wrap gap-3 print:gap-2">
                                    {item.maridaje.text}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}
